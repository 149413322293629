import clsx from "clsx";
import React from "react";
import { NavLink, useHistory } from "react-router-dom";

import ButtonBase from "@material-ui/core/ButtonBase";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import Favorite from "@material-ui/icons/Favorite";
import MenuIcon from "@material-ui/icons/Menu";

import { color } from "../../../../assets/themes";
import SiteMapContext from "../../../contexts/SiteMapContext";
import { useToggle } from "../../../helpers/hooks/use-toggle";
import { Links } from "../../PageManager";
import { Section } from "../section";
import RootState from "../../../../state/root/RootState";
import { useSelector } from "react-redux";

// styles
const useSubMenuStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.primary.main,
      "@media print": {
        display: "none",
      },
    },
    section: {
      padding: theme.spacing(1.5, 5),
      [theme.breakpoints.up("sm")]: {
        // padding: theme.spacing(5),
      },
    },
    spacingBottom: {
      paddingBottom: theme.spacing(3.5),
    },
    titleContainer: {
      border: "1px solid #80cce5",
      borderRadius: 5,
      padding: theme.spacing(0, 2.5),
      [theme.breakpoints.up("lg")]: {
        border: "none",
        borderRadius: 0,
        padding: 0,
        flexGrow: 0,
        borderRight: "1px solid white",
        paddingRight: theme.spacing(6),
        minWidth: 150,
        marginRight: theme.spacing(8),
      },
    },
    breakline: {
      display: "none",
      [theme.breakpoints.up("lg")]: {
        display: "block",
      },
    },
    semiBold: {
      fontWeight: 500,
      fontSize: "18px",
    },
    base: {
      fontWeight: 500,
      fontSize: "18px",
      color: theme.palette.primary.contrastText,
    },
    baseRightSide: {
      fontWeight: 400,
      fontSize: "16px",
      color: theme.palette.primary.contrastText,
    },
    bold: {
      fontWeight: 500,
    },
    iconBtn: {
      paddingRight: theme.spacing(1),
      // backgroundColor: theme.palette.primary.dark,
      "&:hover": {
        // backgroundColor: theme.palette.primary.dark,
      },
    },
    iconBtnRoot: {
      fontSize: "16px",
    },
    iconBtnMobile: {
      paddingLeft: theme.spacing(0),
      // backgroundColor: theme.palette.primary.dark,
      "&:hover": {
        // backgroundColor: theme.palette.primary.dark,
      },
    },
    icon: {
      fill: theme.palette.primary.contrastText,
      // "&.MuiSvgIcon-root": {
      //   fontSize: "16px",
      // },
    },
    link: {
      "&::after": {
        content: '""',
        position: "absolute",
        width: "100%",
        height: "2px",
        bottom: "0px",
        left: "0px",
        backgroundColor: color.white,
        margin: "0px 0px -10px 0px",
        visibility: "hidden",
        transform: "scaleX(0)",
        transition: "all 0.4s ease-in-out 0s",
      },
      "&:hover::after": {
        transform: "scaleX(1)",
        visibility: "visible",
      },
    },
    linkWithUnderline: {
      "&::after": {
        content: '""',
        position: "absolute",
        width: "100%",
        height: "2px",
        bottom: "0px",
        left: "0px",
        backgroundColor: color.white,
        margin: "0px 0px -10px 0px",
        visibility: "visible",
        transform: "scaleX(1)",
      },
    },
    visited: {
      "&::after": {
        content: '""',
        position: "absolute",
        width: "100%",
        height: "2px",
        bottom: "0px",
        left: "0px",
        backgroundColor: color.white,
        margin: "0px 0px -10px 0px",
        transform: "scaleX(1)",
        visibility: "visible",
      },
      "&": {
        pointerEvents: "none",
        cursor: "default",
      },
    },
    menuLgdown: {
      "&:last-child": {
        marginBottom: theme.spacing(2.5),
      },
      "&:first-child": {
        marginTop: theme.spacing(1),
        borderTop: "1px solid #80cce5",
      },
      "&:not(:last-child)": {
        borderBottom: "1px solid #80cce5",
      },
    },
    searchContainer: {
      backgroundColor: theme.palette.primary.dark,
    },
    searchSection: {
      padding: theme.spacing(2.5),
    },
    input: {
      color: theme.palette.primary.contrastText,
      "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
        color: theme.palette.primary.contrastText,
        borderColor: theme.palette.primary.contrastText,
      },
      "&.MuiInput-underline:before": {
        color: theme.palette.primary.contrastText,
        borderColor: theme.palette.primary.contrastText,
      },
      "&.MuiInput-underline:after": {
        color: theme.palette.primary.contrastText,
        borderColor: theme.palette.primary.contrastText,
      },
    },
  })
);

export const SubMenu: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const siteMap = React.useContext(SiteMapContext);
  const [pathname, setPathname] = React.useState<string>("");

  React.useEffect(() => {
    setPathname(history.location.pathname);
  }, [history.location.pathname]);

  const {
    models: { open },
    operations: { handleToggle },
  } = useToggle();

  const {
    models: { open: isSubMenuOpen },
    operations: { handleToggle: handleToggleSubMenu },
  } = useToggle();
  const classes = useSubMenuStyles({ isSubMenuOpen });

  const { isDetailPage } = useSelector((state: RootState) => state.LayoutReducer);
  // TODO: hack, must fix
  const HiddenHack: any = Hidden;
  const menuLgUp = (
    <HiddenHack mdDown>
      <Grid item lg container spacing={6}>
        {siteMap &&
          siteMap.PrimaryMenuLinks?.map((value) => {
            return (
              <Grid item key={value.Title}>
                <Link
                  className={clsx(
                    classes.base,
                    `${
                      pathname.indexOf(value.Path) > -1 && !isDetailPage
                        ? classes.visited
                        : pathname.indexOf(value.Path.substring(0, value.Path.length - 1)) > -1
                        ? classes.linkWithUnderline
                        : classes.link
                    }`
                  )}
                  component="button"
                  variant="body2"
                  color="textPrimary"
                  underline="none"
                  onClick={() => {
                    history.push(value.Path);
                  }}
                >
                  {value.Title}
                </Link>
              </Grid>
            );
          })}
      </Grid>

      <Grid item>
        {/* <Grid container justify="flex-end" alignItems="center" spacing={2}> */}
        <Grid container style={{ marginLeft: "auto" }} alignItems="center" spacing={2}>
          <Grid item>
            <Link
              className={clsx(classes.baseRightSide, classes.bold, `${pathname.indexOf(Links.GetMoreDataLink.Path) > -1 ? classes.visited : classes.link}`)}
              component="button"
              variant="body2"
              color="textPrimary"
              underline="none"
              onClick={() => {
                // history.push(Links.GetMoreDataLink.Path);
                window.open(
                  "https://www.ncqa.org/programs/data-and-information-technology/data-purchase-and-licensing/quality-compass/",
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              {Links.GetMoreDataLink.Title}
            </Link>
          </Grid>
          <Grid item>
            <Link
              className={clsx(classes.baseRightSide, `${pathname.indexOf(Links.GlossaryLink.Path) > -1 ? classes.visited : classes.link}`)}
              component="button"
              variant="body2"
              color="textPrimary"
              underline="none"
              onClick={() => {
                history.push(Links.GlossaryLink.Path);
              }}
            >
              {Links.GlossaryLink.Title}
            </Link>
          </Grid>
          <Grid item>
            <Link
              className={clsx(classes.baseRightSide, `${pathname.indexOf(Links.MethodologyLink.Path) > -1 ? classes.visited : classes.link}`)}
              component="button"
              variant="body2"
              color="textPrimary"
              underline="none"
              onClick={() => {
                history.push(Links.MethodologyLink.Path);
              }}
            >
              {Links.MethodologyLink.Title}
            </Link>
          </Grid>
          <Grid item>
            <IconButton className={classes.iconBtn} onClick={() => history.push(Links.FavoritesLink.Path)}>
              <Favorite className={classes.icon} classes={{ root: classes.iconBtnRoot }} />
            </IconButton>
            <Link
              className={clsx(classes.baseRightSide, `${pathname.indexOf(Links.FavoritesLink.Path) > -1 ? classes.visited : classes.link}`)}
              component="button"
              variant="body2"
              color="textPrimary"
              underline="none"
              onClick={() => {
                history.push(Links.FavoritesLink.Path);
              }}
            >
              {Links.FavoritesLink.Title}
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </HiddenHack>
  );

  const menuLgDown = (
    <HiddenHack lgUp>
      {isSubMenuOpen ? (
        <Grid item lg container direction="column" spacing={4}>
          {siteMap &&
            siteMap.PrimaryMenuLinks?.map((value) => {
              return (
                <Grid item key={value.Title} className={classes.menuLgdown}>
                  <Link
                    className={clsx(classes.base, classes.link)}
                    component="button"
                    variant="body2"
                    color="textPrimary"
                    underline="none"
                    onClick={() => {
                      history.push(value.Path);
                      handleToggleSubMenu();
                    }}
                  >
                    {value.Title}
                  </Link>
                </Grid>
              );
            })}
          <Grid item>
            <Link
              className={clsx(classes.baseRightSide, classes.link, classes.bold)}
              component="button"
              variant="body2"
              color="textPrimary"
              underline="none"
              onClick={() => {
                // history.push(Links.GetMoreDataLink.Path);
                window.open(
                  "https://www.ncqa.org/programs/data-and-information-technology/data-purchase-and-licensing/quality-compass/",
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              {Links.GetMoreDataLink.Title}
            </Link>
          </Grid>
          <Grid item>
            <Link
              className={clsx(classes.baseRightSide, classes.link)}
              component="button"
              variant="body2"
              color="textPrimary"
              underline="none"
              onClick={() => {
                history.push(Links.GlossaryLink.Path);
                handleToggleSubMenu();
              }}
            >
              {Links.GlossaryLink.Title}
            </Link>
          </Grid>
          <Grid item>
            <Link
              className={clsx(classes.baseRightSide, classes.link)}
              component="button"
              variant="body2"
              color="textPrimary"
              underline="none"
              onClick={() => {
                history.push(Links.MethodologyLink.Path);
                handleToggleSubMenu();
              }}
            >
              {Links.MethodologyLink.Title}
            </Link>
          </Grid>
          <Grid item style={{ marginLeft: -12, marginTop: -15 }}>
            <IconButton className={classes.iconBtn} onClick={() => history.push(Links.FavoritesLink.Path)}>
              <Favorite className={classes.icon} classes={{ root: classes.iconBtnRoot }} />
            </IconButton>
            <Link
              className={clsx(classes.baseRightSide, classes.link)}
              component="button"
              variant="body2"
              color="textPrimary"
              underline="none"
              onClick={() => {
                history.push(Links.FavoritesLink.Path);
                handleToggleSubMenu();
              }}
            >
              {Links.FavoritesLink.Title}
            </Link>
          </Grid>
        </Grid>
      ) : null}
    </HiddenHack>
  );

  return (
    <Grid className={classes.container}>
      <Section container className={classes.section} alignItems="center">
        <Grid
          item
          container
          className={clsx(classes.titleContainer, {
            [classes.spacingBottom]: isSubMenuOpen,
          })}
          xs={lgUp ? undefined : true}
          alignItems={lgUp ? undefined : "center"}
          justify={lgUp ? undefined : "space-between"}
          lg
        >
          <Grid item>
            <Link
              className={clsx(classes.link)}
              component="button"
              variant="body2"
              color="textPrimary"
              underline="none"
              onClick={() => {
                history.push(Links.WelcomeLink.Path);
              }}
            >
              <Typography className={classes.base}>REPORT CARDS</Typography>
            </Link>
          </Grid>
          <HiddenHack lgUp>
            <Grid item>
              <IconButton aria-label="menu" onClick={handleToggleSubMenu}>
                {isSubMenuOpen ? <CloseIcon className={classes.icon} /> : <MenuIcon className={classes.icon} />}
              </IconButton>
            </Grid>
          </HiddenHack>
          {/* menu - lgDown */}
          {menuLgDown}
        </Grid>

        {/* menus - lgUp */}
        {menuLgUp}
      </Section>
      {open ? (
        <Grid className={classes.searchContainer}>
          <Section className={classes.searchSection}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <Favorite className={classes.icon} />
              </Grid>
              <Grid xs item>
                <TextField
                  placeholder="Search Digital Measurement Community"
                  fullWidth
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </Grid>
              <Grid item>
                <ButtonBase onClick={handleToggle}>
                  <CloseIcon className={classes.icon} />
                </ButtonBase>
              </Grid>
            </Grid>
          </Section>
        </Grid>
      ) : null}
    </Grid>
  );
};
