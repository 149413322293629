import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { AuthenticationReducer } from "../authentication";
import { FavoritesReducer } from "../favorites";
import { LayoutReducer } from "../layout";

const BaseRootReducer = combineReducers({
  AuthenticationReducer,
  LayoutReducer,
  FavoritesReducer,
});

// Redux Persist
// For state that we want to persist through page refreshes
// Persist Authentication
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["AuthenticationReducer"],
};

// Add persistance to the base root reducer
const RootReducer = persistReducer(persistConfig, BaseRootReducer);

export { RootReducer };
