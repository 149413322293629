import * as React from "react";
import { UserManager } from "oidc-client";

interface IOwnProps {
  userManager: UserManager;
}

const SilentRenewFn = () => {
  React.useEffect(() => {
    // Automatic silent renew has been enabled so oidc-client will transparently handle
    // that for us. The silent renew callback allows the oidc-client to know
    // whether the silent renew was successful or not. In either case the oidc-client will
    // trigger events that can be listened to. These events are listened to in the App component.
    // this.props.userManager
    //   .signinSilentCallback()
    //   .then(user => {
    //     // do nothing
    //   })
    //   .catch(error => {
    //     // do nothing
    //   });
    console.log("silent renew");
    new UserManager({}).signinSilentCallback();
  }, []);

  return null;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class SilentRenew extends React.Component<IOwnProps> {
  constructor(props: IOwnProps) {
    super(props);

    // Automatic silent renew has been enabled so oidc-client will transparently handle
    // that for us. The silent renew callback allows the oidc-client to know
    // whether the silent renew was successful or not. In either case the oidc-client will
    // trigger events that can be listened to. These events are listened to in the App component.
    // this.props.userManager
    //   .signinSilentCallback()
    //   .then(user => {
    //     // do nothing
    //   })
    //   .catch(error => {
    //     // do nothing
    //   });
    new UserManager({}).signinSilentCallback();
  }

  render() {
    return null;
  }
}

export default SilentRenewFn;
