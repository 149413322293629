import clsx from "clsx";
import * as React from "react";
import { useSelector } from "react-redux";

import { Button, makeStyles, Theme } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";

import RootState from "../../state/root/RootState";
import { Links } from "../root/PageManager";

// STYLES
const useStyles = makeStyles(
  (theme: Theme) => ({
    iconSmall: {
      fontSize: 20,
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
  }),
  { index: 1 }
);

export default function LogoutButton() {
  const classes = useStyles();
  const user = useSelector((state: RootState) => state.AuthenticationReducer.user);
  return (
    <Button size="small" color="inherit" component={Links.LogoutLink.ToButtonComponent}>
      <PersonIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
      {Links.LogoutLink.Title}
    </Button>
  );
}
