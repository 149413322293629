import clsx from "clsx";
/* eslint-disable no-debugger */
import useIsMounted from "ismounted";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import AppBar, { AppBarProps } from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import EmailIcon from "@material-ui/icons/Email";
import PersonIcon from "@material-ui/icons/Person";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import RootState from "../../../../state/root/RootState";
import { Links } from "../../PageManager";
import { Section } from "../section";
import { Logo } from "../ui";
import HeaderTopMenuLogin from "./HeaderTopMenuLogin";
import { SubMenu } from "./SubMenu";

export interface HeaderProps extends AppBarProps {}

// styles
const useHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      padding: theme.spacing(0),
      [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(5, 5, 0, 5),
      },
      "@media print": {
        display: "none",
      },
    },
    logoContainer: {
      padding: theme.spacing(2.5),
    },

    menu: {
      marginRight: -20,
    },
    mobileLogin: {
      width: theme.spacing(30),
    },
    link: {
      padding: theme.spacing(2),
      "&:hover": {
        color: theme.palette.secondary.main,
        svg: {
          fill: theme.palette.secondary.main,
        },
      },
      "&:last-child": {
        // marginLeft: theme.spacing(4),
      },
    },
    linkIcon: {
      height: 15,
      width: 15,
      marginRight: theme.spacing(1),
      color: "#9ea2a2",
    },
    iconText: {
      fontWeight: 400,
      fontSize: "16px",
      color: "#54585a",
      // paddingBottom: theme.spacing(2)
    },
    loggedIn: {
      pointerEvents: "none",
      cursor: "default",
    },
  })
);

export const Header: React.FC<HeaderProps> = ({ color = "transparent", ...props }) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useHeaderStyles();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.AuthenticationReducer.user);
  // TODO: hack, must fix
  const HiddenHack: any = Hidden;

  return (
    <>
      {/* <AppBar color={color} {...props}> */}
      {/* <AppBar position="fixed" color="default" component="header"> */}
      <Section container className={classes.container}>
        {/* Logo */}
        <Grid container={smUp ? undefined : true} alignItems={smUp ? undefined : "center"} item xs className={classes.logoContainer} lg={3}>
          <Logo />
        </Grid>
        <HiddenHack lgUp>
          <HeaderTopMenuLogin />
        </HiddenHack>

        {/* top menu */}
        <HiddenHack mdDown>
          <Grid container direction="column" alignItems="flex-end" justify="center" item lg={9} spacing={10}>
            <Grid component="nav" item>
              <Link
                key="shopourproduct"
                className={classes.link}
                component="button"
                variant="body2"
                color="textPrimary"
                underline="none"
                onClick={() => {
                  window.open("https://store.ncqa.org", "_blank");
                }}
              >
                <ShoppingCartIcon className={classes.linkIcon} />
                <span className={classes.iconText}>Shop Our Products</span>
              </Link>
              <Link
                key="contactus"
                className={classes.link}
                component="button"
                variant="body2"
                color="textPrimary"
                underline="none"
                onClick={() => {
                  window.open("https://www.ncqa.org/about-ncqa/contact-us/", "_blank");
                }}
              >
                <EmailIcon className={classes.linkIcon} />
                <span className={classes.iconText}>Contact us </span>
              </Link>

              {user == null ? (
                <Link
                  key="login"
                  className={classes.link}
                  component="button"
                  variant="body2"
                  color="textPrimary"
                  underline="none"
                  onClick={() => {
                    const url = new URL(window.location.href);
                    let pathname = "";
                    if (process.env.PUBLIC_URL !== "") {
                      const p = window.location.href.toLowerCase().indexOf(process.env.PUBLIC_URL.toLowerCase()) + process.env.PUBLIC_URL.length;
                      pathname = window.location.href.substring(p);
                    } else {
                      pathname = url.pathname + url.search;
                    }
                    if (pathname !== "" && pathname !== "/") {
                      history.push(Links.LoginLink.Path, { lastLocation: pathname });
                    } else {
                      history.push(Links.LoginLink.Path);
                    }
                  }}
                >
                  <PersonIcon className={classes.linkIcon} />
                  <span className={classes.iconText}>Login </span>
                </Link>
              ) : (
                <>
                  <Link
                    key="loggedinperson"
                    className={clsx(classes.iconText, classes.loggedIn)}
                    component="button"
                    variant="body2"
                    color="textPrimary"
                    underline="none"
                  >
                    <PersonIcon className={classes.iconText} />
                    <span className={classes.iconText}>
                      {user && user.profile && `${user.profile.given_name} ${user.profile.family_name?.substring(0, 1)}.`}{" "}
                    </span>
                  </Link>
                  <Link
                    key="logout"
                    className={classes.link}
                    component="button"
                    variant="body2"
                    color="textPrimary"
                    underline="none"
                    onClick={() => {
                      history.push(Links.LogoutLink.Path);
                    }}
                  >
                    <span className={classes.iconText}>Logout</span>
                  </Link>
                </>
              )}
            </Grid>
          </Grid>
        </HiddenHack>
      </Section>
      <SubMenu />
      {/* </AppBar> */}
    </>
  );
};
