/* eslint-disable no-debugger */
import * as React from "react";
import { UserManager } from "oidc-client";
import { useHistory } from "react-router-dom";

interface IOwnProps {
  userManager: UserManager;
}

const Login = (props: IOwnProps) => {
  const history = useHistory();
  React.useEffect(() => {
    // Redirect to the authorization endpoint
    // If the user is not logged in then the Sso login dialog gets shown
    // and once the user logs in then redirected back to the callback url
    // If user is already logged into the Identity Server then redirected back to the callback url
    // callback component processes response from the authorization endpoint.

    // history should have the last location since we populate this value in the login button or when we click a link to Login.
    // the value that goes in location.state is read from the url.
    if (history && history.location && history.location.state) {
      // @ts-ignore
      const { lastLocation } = history.location.state;
      props.userManager.signinRedirect({ state: lastLocation });
    } else {
      props.userManager.signinRedirect();
    }
  }, [props.userManager, history]);

  return null;
};

export default Login;
