import { useSnackbar } from "notistack";
import { User } from "oidc-client";
import { parse, stringify } from "querystring";
import React, { lazy, LazyExoticComponent } from "react";
import { asyncComponent } from "react-async-component";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ButtonProps } from "@material-ui/core/Button";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

import * as Policies from "../../configuration/AuthorizationPolicies";
import { Client } from "../../services/DataAccess";
import * as actions from "../../state/favorites";
import RootState from "../../state/root/RootState";
import SiteMapContext from "../contexts/SiteMapContext";
import Login from "../open-id-connect/Login";
import PageLayout from "./PageLayout";

const LogoutAsync = lazy(() => import("../open-id-connect/Logout"));

const LoginAsync = lazy(() => import("../open-id-connect/Login"));

const NotFoundAsync = lazy(() => import("../pages/unsecured/NotFound"));

const WelcomeAsync = lazy(() => import("../pages/unsecured/welcome/Welcome"));

const HealthPlanListViewAsync = lazy(() => import("../pages/primary/health-plans/list-view/HealthPlanListView"));

const HealthPlanDetailViewAsync = lazy(() => import("../pages/primary/health-plans/detail-view/HealthPlanDetail"));

const OtherOrgDetailViewAsync = lazy(() => import("../pages/primary/other-orgs/detail-view/OtherOrgDetailView"));

const OtherOrgListViewAsync = lazy(() => import("../pages/primary/other-orgs/list-view/OtherOrgListView"));

const PracticeDetailViewAsync = lazy(() => import("../pages/primary/practices/detail-view/PracticeDetail"));

const PracticeListViewAsync = lazy(() => import("../pages/primary/practices/list-view/PracticeListView"));

const ProviderDetailViewAsync = lazy(() => import("../pages/primary/providers/detail-view/ProviderDetail"));

const ProviderListViewAsync = lazy(() => import("../pages/primary/providers/list-view/ProviderListView"));

const GetMoreDataAsync = lazy(() => import("../pages/get-more-data/GetMoreData"));

const GlossaryAsync = lazy(() => import("../pages/glossary/Glossary"));

const MethodologyAsync = lazy(() => import("../pages/methodology/Methodology"));

const FavoritesAsync = lazy(() => import("../pages/favorites/Favorites"));

// const LogoutAsync = asyncComponent({
//   resolve: () => import("../open-id-connect/Logout"),
// });

// const LoginAsync = asyncComponent({
//   resolve: () => import("../open-id-connect/Login"),
// });

// const NotFoundAsync = asyncComponent({
//   resolve: () => import("../pages/unsecured/NotFound"),
// });

// const WelcomeAsync = asyncComponent({
//   resolve: () => import("../pages/unsecured/welcome/Welcome"),
// });

// const HealthPlanListViewAsync = asyncComponent({
//   resolve: () => import("../pages/primary/health-plans/list-view/HealthPlanListView"),
// });

// const HealthPlanDetailViewAsync = asyncComponent({
//   resolve: () => import("../pages/primary/health-plans/detail-view/HealthPlanDetailView"),
// });

// const OtherOrgDetailViewAsync = asyncComponent({
//   resolve: () => import("../pages/primary/other-orgs/detail-view/OtherOrgDetailView"),
// });

// const OtherOrgListViewAsync = asyncComponent({
//   resolve: () => import("../pages/primary/other-orgs/list-view/OtherOrgListView"),
// });

// const PracticeDetailViewAsync = asyncComponent({
//   resolve: () => import("../pages/primary/practices/PracticeDetailView"),
// });

// const PracticeListViewAsync = asyncComponent({
//   resolve: () => import("../pages/primary/practices/list-view/PracticeListView"),
// });

// const ProviderDetailViewAsync = asyncComponent({
//   resolve: () => import("../pages/primary/providers/detail-view/ProviderDetailView"),
// });

// const ProviderListViewAsync = asyncComponent({
//   resolve: () => import("../pages/primary/providers/list-view/ProviderListView"),
// });

// const GetMoreDataAsync = asyncComponent({
//   resolve: () => import("../pages/get-more-data/GetMoreData"),
// });

// const GlossaryAsync = asyncComponent({
//   resolve: () => import("../pages/glossary/Glossary"),
// });

// const MethodologyAsync = asyncComponent({
//   resolve: () => import("../pages/methodology/Methodology"),
// });

// const FavoritesAsync = asyncComponent({
//   resolve: () => import("../pages/favorites/Favorites"),
// });
// Link Interface
// This wraps a link and is used to generate the link page, header and menu items
export interface ILink {
  Component: React.ComponentType;
  Path: string;
  Policy?: (user: User | null) => boolean;
  PolicyName?: Policies.PolicyName;
  Title: string;
  ToButtonComponent: ({ innerRef, ...props }: ButtonProps) => JSX.Element;
}

// Link Helper
const determinePolicy = (policyName?: Policies.PolicyName) => {
  switch (policyName) {
    case "user":
      return Policies.IsUserPolicy;
    case "admin":
      return Policies.IsAdminPolicy;
    case "superadmin":
      return Policies.IsSuperAdminPolicy;
    default:
      return undefined;
  }
};

// This function will pass the query string params on the current page to the link
const AddQueryParamsToLink =
  (path: string) =>
  ({ innerRef, ...props }: ButtonProps) => {
    // Get the current params
    const currentParams = parse(window.location.search.replace("?", ""));

    // @ts-ignore
    return <Link to={{ pathname: path, search: stringify(currentParams) }} {...props} />;
  };

export const createLink = (title: string, path: string, component: React.ComponentType | LazyExoticComponent<any>, policyName?: Policies.PolicyName): ILink => {
  return {
    Component: component,
    Path: path,
    Policy: determinePolicy(policyName),
    PolicyName: policyName,
    Title: title,
    // @ts-ignore
    ToButtonComponent: React.forwardRef((props, ref) => <Link to={path} {...props} ref={ref as any} />),
  };
};

// MENU SYSTEM
export interface IMenu {
  Name: string;
  Links: ILink[];
}

export interface ISiteMap {
  AllLinks: ILink[];
  PrimaryMenuLinks: ILink[];
  SecondaryMenuLinks: ILink[];
  OtherLinks: ILink[];
  Menus: IMenu[] | null;
}

const OpenIdLinks = {
  LoginLink: {
    Component: Login,
    Path: "/login",
    Title: "Login",
    ToButtonComponent: AddQueryParamsToLink("/login"),
  } as unknown as ILink,
  LogoutLink: createLink("Logout", "/logout", LogoutAsync),
};

// Unsecured pages
const UnsecuredLinks = {
  NotFoundLink: createLink("Not Found", "/notfound", NotFoundAsync),
  WelcomeLink: createLink("Welcome", "/", WelcomeAsync),
};

const PageLinks = {
  HealthPlanListViewLink: createLink("Health Plans", "/health-plans", HealthPlanListViewAsync),
  HealthPlanDetailViewLink: createLink("Health plan detail view", "/health-plan", HealthPlanDetailViewAsync),
  PracticeDetailViewLink: createLink("Practices detail view", "/practice", PracticeDetailViewAsync),
  PracticeListViewLink: createLink("Practices", "/practices", PracticeListViewAsync),
  ProviderDetailViewLink: createLink("Clinicians detail view", "/clinician", ProviderDetailViewAsync),
  ProviderListViewLink: createLink("Clinicians", "/clinicians", ProviderListViewAsync),
  OtherOrgDetailViewLink: createLink("Other Health Care Organizations", "/other-health-care-organization", OtherOrgDetailViewAsync),
  OtherOrgListViewLink: createLink("Other Health Care Organizations", "/other-health-care-organizations", OtherOrgListViewAsync),
};

const SecondaryPageLinks = {
  GetMoreDataLink: createLink("Get More Data", "/more-data", GetMoreDataAsync),
  GlossaryLink: createLink("Glossary", "/glossary", GlossaryAsync),
  MethodologyLink: createLink("Methodology", "/methodology", MethodologyAsync),
  FavoritesLink: createLink("My Saved", "/my-favorites", FavoritesAsync),
};

// Join all the links
export const Links: { [key: string]: ILink } = { ...OpenIdLinks, ...UnsecuredLinks, ...PageLinks, ...SecondaryPageLinks };

const PageManager = () => {
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state: RootState) => state.AuthenticationReducer.user);
  const dispatch = useDispatch();
  const appInsights = useAppInsightsContext();
  React.useEffect(() => {
    // if user exists then get all the favorites and save to redux
    if (user) {
      // eslint-disable-next-line consistent-return
      (async () => {
        try {
          const dataAccess = new Client(user);
          const fav = await dataAccess.getFavorites();
          console.log("favorites from api @ login", fav);
          dispatch(actions.LoadFavoritesSuccess(fav));
        } catch (error: any) {
          if (error.response?.status !== 401) {
            if (error.status === 500) {
              appInsights.trackException({ exception: new Error(error.title), severityLevel: SeverityLevel.Error });
              enqueueSnackbar(`${error.title}`, {
                variant: "error",
              });
            } else {
              appInsights.trackException({ exception: new Error(error.message), severityLevel: SeverityLevel.Error });
              enqueueSnackbar(`${error.message}`, {
                variant: "error",
              });
            }
          }

          // TODO front end error reporting say to one of these https://github.com/cheeaun/javascript-error-logging
          return Promise.reject(error);
        }
      })();
    }
  }, [user, enqueueSnackbar, dispatch, appInsights]);

  const createSiteMap = () => {
    const allLinks = CreateAllLinks();
    const primaryLinks = CreatePrimaryMenuLinks();
    const secondaryMenuLinks = CreateSecondaryMenuLinks();
    const otherLinks = CreateOtherLinks();
    const siteMap: ISiteMap = {
      AllLinks: allLinks,
      Menus: null,
      PrimaryMenuLinks: primaryLinks,
      SecondaryMenuLinks: secondaryMenuLinks,
      OtherLinks: otherLinks,
    };

    return siteMap;
  };

  return (
    <SiteMapContext.Provider value={createSiteMap()}>
      <PageLayout />
    </SiteMapContext.Provider>
  );
};

const CreateAllLinks = (): ILink[] => {
  return Object.keys(Links)?.map<ILink>((key) => Links[key]);
};

const CreatePrimaryMenuLinks = (): ILink[] => {
  return [Links.HealthPlanListViewLink, Links.ProviderListViewLink, Links.PracticeListViewLink, Links.OtherOrgListViewLink];
};

const CreateSecondaryMenuLinks = (): ILink[] => {
  return [Links.GlossaryLink, Links.GetMoreDataLink, Links.FavoritesLink, Links.MethodologyLink];
};

const CreateOtherLinks = (): ILink[] => {
  return [Links.HealthPlanDetailViewLink, Links.OtherOrgDetailViewLink, Links.PracticeDetailViewLink, Links.ProviderDetailViewLink];
};

export default PageManager;
