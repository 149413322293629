import clsx from "clsx";
import * as React from "react";
import { useHistory } from "react-router-dom";

import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";

import { Links } from "../root/PageManager";

// STYLES
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconSmall: {
      fontSize: 20,
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
  })
);

export default function LoginButton() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Button
      size="small"
      color="inherit"
      onClick={() => {
        const url = new URL(window.location.href);
        let pathname = "";
        if (process.env.PUBLIC_URL !== "") {
          const p = window.location.href.toLowerCase().indexOf(process.env.PUBLIC_URL.toLowerCase()) + process.env.PUBLIC_URL.length;
          pathname = window.location.href.substring(p);
        } else {
          pathname = url.pathname + url.search;
        }
        if (pathname !== "" && pathname !== "/") {
          history.push(Links.LoginLink.Path, { lastLocation: pathname });
        } else {
          history.push(Links.LoginLink.Path);
        }
        if (pathname !== "" && pathname !== "/") {
          history.push(Links.LoginLink.Path, { lastLocation: pathname });
        } else {
          history.push(Links.LoginLink.Path);
        }
      }}
    >
      <PersonIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
      {Links.LoginLink.Title}
    </Button>
  );
}
