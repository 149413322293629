import clsx from "clsx";
import React from "react";

import Grid, { GridProps } from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

// styles
const useSectionStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      width: "100%",
      maxWidth: 1240,
    },
  })
);

export const Section: React.FC<GridProps> = ({ className, children, ...props }) => {
  const classes = useSectionStyles();

  return (
    <Grid container justify="center">
      <Grid component="section" className={clsx(classes.section, className)} {...props}>
        {children}
      </Grid>
    </Grid>
  );
};
