import { createTheme } from "@material-ui/core/styles";

import { color } from "./color";
import { font } from "./font";

const theme = createTheme({
  // spacing: (factor) => `${0.25 * factor}rem`,
  spacing: (factor) => `${0.25 * factor * 16}px`,
  palette: {
    primary: {
      light: color.primary.light,
      main: color.primary.main,
      dark: color.primary.dark,
      contrastText: color.primary.contrastText,
    },
    secondary: {
      light: color.secondary.light,
      main: color.secondary.main,
      dark: color.secondary.dark,
      contrastText: color.secondary.contrastText,
    },
    text: {
      primary: color.text.primary,
      secondary: color.text.secondary,
    },
    background: {
      default: color.white,
    },
  },
  typography: {
    fontFamily: font.primary,
    h1: {
      fontFamily: font.primary,
      fontWeight: 700,
      fontSize: "36px",
    },
    h2: {
      fontFamily: font.primary,
      fontWeight: 500,
      fontSize: "32px",
      color: color.text.secondary,
    },
    h3: {
      fontFamily: font.primary,
      fontWeight: 700,
      fontSize: "48px",
    },
    h4: {
      fontFamily: font.primary,
      fontWeight: 700,
      fontSize: "34px",
    },
    h5: {
      fontFamily: font.primary,
      fontWeight: 400,
      fontSize: "24px",
      lineHeight: "32px",
    },
    h6: {
      fontFamily: font.primary,
      fontWeight: 700,
      fontSize: "20px",
    },
    subtitle1: {
      fontFamily: font.primary,
      fontWeight: 400,
      fontSize: "16px",
    },
    subtitle2: {
      fontFamily: font.primary,
      fontWeight: 700,
      fontSize: "14px",
    },
    body1: {
      fontFamily: font.primary,
      fontWeight: 400,
      fontSize: "14px",
      whiteSpace: "pre-wrap",
    },
    body2: {
      fontFamily: font.primary,
      color: color.text.primary,
      fontWeight: 400,
      fontSize: "14px",
      // whiteSpace: "pre-wrap",
    },
    button: {
      fontFamily: font.primary,
      fontSize: "14px",
      lineHeight: "28px",
      textTransform: "none",
      letterSpacing: "0.45712px",
    },
    caption: {
      fontFamily: font.primary,
      fontWeight: 400,
      fontSize: "12px",
    },
    overline: {
      fontFamily: font.primary,
      fontWeight: 400,
      fontSize: "12px",
    },
  },
});

export { theme, color, font };
