/* eslint-disable no-debugger */
import "./index.css";
import "reflect-metadata";

import { SnackbarProvider } from "notistack";
import nprogress from "nprogress";
import React, { FC, Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom";
// @ts-ignore
import * as ReactDOMClient from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { BrowserRouter as Router, useHistory, useLocation } from "react-router-dom";
import { createStore } from "redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { SWRConfig } from "swr";

import { Box, Button, CircularProgress, CssBaseline, Grid, MuiThemeProvider, Typography } from "@material-ui/core";
import { Replay } from "@material-ui/icons";
import { AppInsightsContext, AppInsightsErrorBoundary, useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

import { reactPlugin } from "./AppInsights";
import { theme } from "./assets/themes";
import ScrollToTop from "./components/pages/common/ScrollToTop";
import App from "./components/root/App";
import { Header, Section } from "./components/root/layout";
import { ConfiguredUserManager } from "./configuration/AuthenticationConfiguration";
import * as AuthActions from "./state/authentication";
import { RootReducer } from "./state/root/RootReducer";
import { RootStore } from "./state/root/RootStore";

export interface LazyLoaderProps {
  delay?: number;
}

// TODO: should go into their own files
const LazyLoader: FC<LazyLoaderProps> = ({ delay = 250, ...props }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      nprogress.start();
      setShow(true);
    }, delay);
    return () => {
      nprogress.done();
      clearTimeout(timeout);
    };
  }, [delay]);

  return show ? (
    <>
      <Header></Header>
      <Section>
        <Box display="flex" justifyContent="center" mt="50px">
          <div>
            <CircularProgress />
            <CircularProgress color="secondary" />
          </div>
        </Box>
      </Section>
    </>
  ) : null;
};

// TODO: should go into their own files
function ErrorFallback({ error }: { error: any }) {
  const appInsights = useAppInsightsContext();
  appInsights.trackException({ exception: new Error(error.message), severityLevel: SeverityLevel.Error });
  const history = useHistory();
  return (
    <>
      <Header></Header>
      <Section>
        <Box display="flex" justifyContent="center" style={{ flexDirection: "column", maxWidth: "300px", marginLeft: "auto", marginRight: "auto" }}>
          <Typography variant="h5">
            <div role="alert">
              <p style={{ textAlign: "center" }}>Something went wrong:</p>
              <pre style={{ color: "red", textAlign: "center" }}>{error.message}</pre>
            </div>
          </Typography>
          <Button
            onClick={() => {
              const u = new URL(window.location.href);
              // strip out the query string that might be causing the error
              // and then push the path if it's not the root path
              if (u.pathname != null) history.push(u.pathname);
              // then, reload for good measure
              window.location.reload();
              // not entirely sure why I shouldn't be using below instead of above, will readup later
              // history.go(0);
            }}
            variant="outlined"
            color="primary"
            startIcon={<Replay />}
          >
            Try reloading
          </Button>
        </Box>
      </Section>
    </>
  );
}

// TODO: should go into their own files
function LoadingFallback() {
  return (
    <>
      <Header></Header>
      <Section>
        <Box display="flex" justifyContent="center">
          {/* <Typography variant="h5">
            <div role="alert">
              <p>loading...</p>
            </div>
          </Typography> */}
          <div>
            <CircularProgress />
            <CircularProgress color="secondary" />
          </div>
        </Box>
      </Section>
    </>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// User Manager
const userManager = ConfiguredUserManager;

/* eslint-disable no-underscore-dangle */
const store = createStore(RootReducer, (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()) as RootStore;
/* eslint-enable */

// Redux persist
// Sometimes, the OIDC User Manager signout can redirect a user to the sign out page before Redux Persist is able to persist the user removal to storage
// So, the callback is called every time a rehydrate occurs
// On the rehydrate, check to see if OIDC has removed the user, and if it has and the user still exists in Redux, then dispatch an action to sign the user out
const persistor = persistStore(store, undefined, () => {
  userManager.getUser().then((user) => {
    if (!user && store.getState().AuthenticationReducer.user) {
      store.dispatch(AuthActions.AuthenticationUserSignedOut());
    }
  });
});

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container as Element);
root.render(
  <AppInsightsContext.Provider value={reactPlugin}>
    <SnackbarProvider maxSnack={1} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
      <MuiThemeProvider theme={theme}>
        <>
          <CssBaseline />
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <Router basename={process.env.PUBLIC_URL}>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<LazyLoader delay={300} />}>
                    <SWRConfig value={{ shouldRetryOnError: true, errorRetryCount: 3, revalidateOnFocus: true }}>
                      <ScrollToTop></ScrollToTop>
                      {/* <React.StrictMode> */}
                      <App store={store} userManager={userManager} />
                      {/* </React.StrictMode> */}
                    </SWRConfig>
                  </Suspense>
                </ErrorBoundary>
              </Router>
            </PersistGate>
          </Provider>
        </>
      </MuiThemeProvider>
    </SnackbarProvider>
  </AppInsightsContext.Provider>
);

// ReactDOM.render(
//   <AppInsightsContext.Provider value={reactPlugin}>
//     <SnackbarProvider maxSnack={1} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
//       <MuiThemeProvider theme={theme}>
//         <>
//           <CssBaseline />
//           <Provider store={store}>
//             <PersistGate persistor={persistor}>
//               <Router basename={process.env.PUBLIC_URL}>
//                 <ErrorBoundary FallbackComponent={ErrorFallback}>
//                   <Suspense fallback={<LazyLoader delay={300} />}>
//                     <SWRConfig value={{ shouldRetryOnError: true, errorRetryCount: 3, revalidateOnFocus: true }}>
//                       <React.StrictMode>
//                         <ScrollToTop></ScrollToTop>
//                         <App store={store} userManager={userManager} />
//                       </React.StrictMode>
//                     </SWRConfig>
//                   </Suspense>
//                 </ErrorBoundary>
//               </Router>
//             </PersistGate>
//           </Provider>
//         </>
//       </MuiThemeProvider>
//     </SnackbarProvider>
//   </AppInsightsContext.Provider>,
//   document.getElementById("root")
// );
