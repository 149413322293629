import * as React from "react";
import { UserManager } from "oidc-client";

interface IOwnProps {
  userManager?: UserManager;
}

const Logout = (props: IOwnProps) => {
  React.useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    props.userManager && props.userManager.signoutRedirect();
  }, [props.userManager]);

  return null;
};

export default Logout;
