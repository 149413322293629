/* eslint-disable no-debugger */
import * as React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import RootState from "../../state/root/RootState";
import AuthorizedRoute from "../authorization/AuthorizedRoute";
import SiteMapContext from "../contexts/SiteMapContext";
import { Links } from "./PageManager";

const MainView = () => {
  const siteMap = React.useContext(SiteMapContext);
  const user = useSelector((state: RootState) => state.AuthenticationReducer.user);

  const pageExists = (): boolean => {
    if (window.location.pathname === "/") {
      return true;
    }
    if (siteMap) {
      const paths = siteMap.AllLinks.flatMap((value) => value.Path);
      if (paths.indexOf(window.location.pathname) > -1) {
        return true;
      }
    }
    return false;
  };

  return (
    <Switch>
      {/* Standard Pages - These don't have explicit links in header */}
      <Route exact={true} path={Links.WelcomeLink.Path} component={Links.WelcomeLink.Component} />
      <Route path={Links.NotFoundLink.Path} component={Links.NotFoundLink.Component} />
      {/* <Route path={Links.InsufficientPrivilegesLink.Path} component={Links.InsufficientPrivilegesLink.Component} /> */}

      {/* Links that go in the header */}
      {siteMap && siteMap.PrimaryMenuLinks?.map((link) => <Route key={link.Path} exact={true} path={`${link.Path}`} component={link.Component} />)}
      {siteMap && siteMap.SecondaryMenuLinks?.map((link) => <Route key={link.Path} exact={true} path={`${link.Path}`} component={link.Component} />)}
      {siteMap && siteMap.OtherLinks?.map((link) => <Route key={link.Path} exact={true} path={`${link.Path}/:id`} component={link.Component} />)}

      {!pageExists() && <Route component={Links.NotFoundLink.Component} />}
    </Switch>
  );
};

export default MainView;
