import { User } from "oidc-client";
import { ActionType, createAction } from "typesafe-actions";
import RootAction from "../root/RootAction";

// Constants
export const AUTHENTICATION_USER_EXPIRED = "AUTHENTICATION_USER_EXPIRED";
export const AUTHENTICATION_REDIRECT_SUCCESS = "AUTHENTICATION_REDIRECT_SUCCESS";
export const AUTHENTICATION_USER_LOADED = "AUTHENTICATION_USER_LOADED";
export const AUTHENTICATION_SILENT_RENEW_ERROR = "AUTHENTICATION_SILENT_RENEW_ERROR";
export const AUTHENTICATION_SESSION_TERMINATED = "AUTHENTICATION_SESSION_TERMINATED";
export const AUTHENTICATION_USER_EXPIRING = "AUTHENTICATION_USER_EXPIRING";
export const AUTHENTICATION_USER_FOUND = "AUTHENTICATION_USER_FOUND";
export const AUTHENTICATION_LOADING_USER = "AUTHENTICATION_LOADING_USER";
export const AUTHENTICATION_USER_SIGNED_OUT = "AUTHENTICATION_USER_SIGNED_OUT";
export const AUTHENTICATION_LOAD_USER_ERROR = "AUTHENTICATION_LOAD_USER_ERROR";

// Action creators
export const AuthenticationUserExpired = createAction(AUTHENTICATION_USER_EXPIRED);
export const AuthenticationRedirectSuccess = createAction(AUTHENTICATION_REDIRECT_SUCCESS, (resolve) => {
  return (user: User) => resolve(user);
});
export const AuthenticationUserFound = createAction(AUTHENTICATION_USER_FOUND, (resolve) => {
  return (user: User) => resolve(user);
});
export const AuthenticationSilentRenewError = createAction(AUTHENTICATION_SILENT_RENEW_ERROR, (resolve) => (error?: unknown) => resolve(error));
export const AuthenticationSessionTerminated = createAction(AUTHENTICATION_SESSION_TERMINATED);
export const AuthenticationUserExpiring = createAction(AUTHENTICATION_USER_EXPIRING);
export const AuthenticationLoadingUser = createAction(AUTHENTICATION_LOADING_USER);
export const AuthenticationUserSignedOut = createAction(AUTHENTICATION_USER_SIGNED_OUT);
export const AuthenticationLoadUserError = createAction(AUTHENTICATION_LOAD_USER_ERROR);

// State
type AuthenticationState = {
  isLoading_User: boolean;
  isLoading_User_Error: unknown;
  user: User | null;
};

// Initial state
export const initalState: AuthenticationState = {
  isLoading_User: false,
  isLoading_User_Error: null,
  user: null,
};

export type Action = ActionType<
  | typeof AuthenticationUserExpired
  | typeof AuthenticationRedirectSuccess
  | typeof AuthenticationUserFound
  | typeof AuthenticationSilentRenewError
  | typeof AuthenticationSessionTerminated
  | typeof AuthenticationLoadingUser
  | typeof AuthenticationUserSignedOut
  | typeof AuthenticationLoadUserError
>;

// Reducers
export const AuthenticationReducer = (state: AuthenticationState = initalState, action: RootAction): AuthenticationState => {
  switch (action.type) {
    case AUTHENTICATION_SILENT_RENEW_ERROR:
      return {
        ...state,
        // @ts-ignore
        isLoading_User_Error: action.payload,
      };
    case AUTHENTICATION_USER_EXPIRED:
    case AUTHENTICATION_SESSION_TERMINATED:
    case AUTHENTICATION_USER_SIGNED_OUT:
      return {
        ...state,
        isLoading_User: false,
        isLoading_User_Error: null,
        user: null,
      };
    case AUTHENTICATION_REDIRECT_SUCCESS:
    case AUTHENTICATION_USER_FOUND:
      return { ...state, isLoading_User: false, user: action.payload };
    case AUTHENTICATION_LOADING_USER:
      return {
        ...state,
        isLoading_User: true,
        isLoading_User_Error: null,
        user: null,
      };
    default:
      return state;
  }
};
