import { ActionType, createAction } from "typesafe-actions";
import RootAction from "../root/RootAction";

export const IS_DETAILPAGE = "IS_DETAILPAGE";

export const IsDetailPage = createAction(IS_DETAILPAGE, (resolve) => {
  return (isDetailPage: boolean) => resolve(isDetailPage);
});

type LayoutState = { isDetailPage: boolean };

export const initialState: LayoutState = {
  isDetailPage: false,
};

export type Action = ActionType<typeof IsDetailPage>;

export const LayoutReducer = (state: LayoutState = initialState, action: RootAction): LayoutState => {
  switch (action.type) {
    case IS_DETAILPAGE:
      return {
        ...state,
        isDetailPage: action.payload,
      };
    default:
      return state;
  }
};
