import * as React from "react";
import { User, UserManager } from "oidc-client";
import { Redirect } from "react-router-dom";
import { Links } from "../root/PageManager";

// COMPONENT ATTRIBUTE: These get set by calling component
interface IOwnProps {
  userManager: UserManager;
}

const Callback = (props: IOwnProps) => {
  const [isError, setError] = React.useState(false);
  const [redirectUrl, setRedirectUrl] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    const signInRedirectCallback = (url?: string | undefined): Promise<User> => {
      // Process response from the authorization endpoint
      // oidc-client transparently stores the response in sessionStorage/localStorage
      return props.userManager.signinRedirectCallback();
    };

    (async () => {
      try {
        const user = await signInRedirectCallback();
        if (user && user.state) {
          setRedirectUrl(user.state);
        } else if (user) {
          setRedirectUrl(Links.WelcomeLink.Path);
        } else {
          setError(true);
        }
      } catch (error: any) {
        setError(true);
      }
    })();
  }, [props.userManager]);

  if (isError) {
    return <Redirect to={Links.WelcomeLink.Path} />;
  }
  if (redirectUrl != null) {
    return <Redirect to={redirectUrl} />;
  }
  return null;
};

export default Callback;
