import * as React from "react";

import { Button, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import "react-responsive-modal/styles.css";
import { Header } from "./layout";
import Main from "./Main";
import Modal from "react-responsive-modal";
import { useCookies } from "react-cookie";
import ScrollToTop from "react-scroll-up";
import ArrowUpwardIconTwoTone from "@material-ui/icons/ArrowUpwardTwoTone";
import CacheBuster from "../../cache-buster";

const PageLayout = () => {
  const [cookies, setCookie] = useCookies(["termsOfUseCookie"]);
  const [isOpen, setIsOpen] = React.useState(false);
  const prefersReducedMotion = useMediaQuery("(prefers-reduced-motion: reduce)");

  React.useEffect(() => {
    if (!cookies.termsOfUseCookie) {
      setIsOpen(true);
    } else if (cookies.termsOfUseCookie) {
      setIsOpen(false);
    }
  }, [cookies.termsOfUseCookie]);

  const handleClose = () => {
    setCookie("termsOfUseCookie", true, { maxAge: 31536000 });
  };
  // TODO: hack, must fix
  const ScrollToTopHack: any = ScrollToTop;
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }: { loading: boolean; isLatestVersion: boolean; refreshCacheAndReload: () => null }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <>
            <Modal
              open={isOpen}
              onEscKeyDown={handleClose}
              onClose={() => null}
              center
              closeOnOverlayClick={false}
              showCloseIcon={false}
              animationDuration={prefersReducedMotion ? 0 : 300}
              aria-labelledby="Terms of Use"
              aria-describedby="Please note that NCQA Report Cards are intended for general consumer understanding and education only and are not intended to be a guarantee of
        the quality of any health care organization or clinician and are not a substitute for professional medical advice. Use of the Report Cards are
        entirely at your own risk and NCQA disclaims any responsibility therefore. Press the escape key to accept."
            >
              <span style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h6">Terms of Use</Typography>
                <Typography variant="subtitle1" style={{ marginTop: "5px" }}>
                  By clicking &quot;I accept&quot;, you agree to this site&apos;s{" "}
                  <a href="https://www.ncqa.org/terms-of-use/" target="_blank" rel="noreferrer">
                    Terms of Use
                  </a>{" "}
                  . Please note that NCQA Report Cards are intended for general consumer understanding and education only and are not intended to be a guarantee
                  of the quality of any health care organization or clinician and are not a substitute for professional medical advice. Use of the Report Cards
                  are entirely at your own risk and NCQA disclaims any responsibility therefore.
                </Typography>
                <div style={{ display: "flex", justifyContent: "center", paddingTop: "7px" }}>
                  <Button onClick={handleClose} variant="contained" color="secondary" size="large">
                    I accept
                  </Button>
                </div>
              </span>
            </Modal>
            <Header />
            <Main />
            <ScrollToTopHack showUnder={160} style={{ bottom: 5, right: 5 }} duration={prefersReducedMotion ? 0 : 250}>
              <Button color="primary" variant="text" size="small">
                <ArrowUpwardIconTwoTone color="primary" fontSize="large" fill="blue" />
              </Button>
            </ScrollToTopHack>
          </>
        );
      }}
    </CacheBuster>
  );
};

export default PageLayout;
