import * as React from "react";
import usePageTracking from "../../usePageTracking";
import PageManager from "./PageManager";

const AppWithAuthentication = () => {
  usePageTracking();
  return <PageManager />;
};

export default AppWithAuthentication;
