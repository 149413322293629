import * as React from "react";
import { useSelector } from "react-redux";
import RootState from "../../../../state/root/RootState";
import LoginButton from "../../../authentication/LoginButton";
import LogoutButton from "../../../authentication/LogoutButton";

const HeaderTopMenuLogin = () => {
  const user = useSelector((root: RootState) => root.AuthenticationReducer.user);
  return user ? <LogoutButton /> : <LoginButton />;
};

export default HeaderTopMenuLogin;
