/* eslint-disable no-debugger */
import { UserManager } from "oidc-client";
import * as React from "react";
import { Route, Switch, Redirect, useHistory, useLocation } from "react-router-dom";
import { Store } from "redux";
import RootAction from "../../state/root/RootAction";
import RootState from "../../state/root/RootState";
import Callback from "../open-id-connect/Callback";
import Login from "../open-id-connect/Login";
import Logout from "../open-id-connect/Logout";
import SilentRenew from "../open-id-connect/SilentRenew";
import * as AuthActions from "../../state/authentication";
import AppWithAuthentication from "./AppWithAuthentication";

interface IOwnProps {
  store: Store<RootState, RootAction>;
  userManager: UserManager;
}

const App = (props: IOwnProps) => {
  const [state, setState] = React.useState<{ lastLocation: string; redirect: boolean }>({ lastLocation: "/", redirect: false });
  React.useEffect(() => {
    (async () => {
      // Subscribe to events that are raised on the user manager
      props.userManager.events.addUserLoaded((user) => {
        props.store.dispatch(AuthActions.AuthenticationUserFound(user));
      });
      props.userManager.events.addSilentRenewError(() => {
        props.store.dispatch(AuthActions.AuthenticationUserSignedOut());
      });
      props.userManager.events.addAccessTokenExpired(() => {
        props.store.dispatch(AuthActions.AuthenticationUserExpired());
      });
      props.userManager.events.addAccessTokenExpiring(() => {
        // this.props.store.dispatch(actions.AuthenticationUserExpiring())
      });
      props.userManager.events.addUserUnloaded(() => {
        props.store.dispatch(AuthActions.AuthenticationSessionTerminated());
      });
      props.userManager.events.addUserSignedOut(() => {
        props.store.dispatch(AuthActions.AuthenticationUserSignedOut());
        props.userManager.signoutRedirect();
      });
    })();

    return function cleanup() {
      props.userManager.events.removeUserLoaded((user) => {
        props.store.dispatch(AuthActions.AuthenticationUserFound(user));
      });
      props.userManager.events.removeSilentRenewError(() => {
        props.store.dispatch(AuthActions.AuthenticationUserSignedOut());
      });
      props.userManager.events.removeAccessTokenExpired(() => {
        props.store.dispatch(AuthActions.AuthenticationUserExpired());
      });
      props.userManager.events.removeAccessTokenExpiring(() => {
        // this.props.store.dispatch(actions.AuthenticationUserExpiring())
      });
      props.userManager.events.removeUserUnloaded(() => {
        props.store.dispatch(AuthActions.AuthenticationSessionTerminated());
        props.userManager.signoutRedirect();
      });
      props.userManager.events.removeUserSignedOut(() => {
        props.store.dispatch(AuthActions.AuthenticationUserSignedOut());
        props.userManager.signoutRedirect();
      });
    };
  }, [props.store, props.userManager]);

  React.useEffect(() => {
    // this is for mapping legacy hash based routing to the new browser based routing
    const url = new URL(window.location.href);
    if (window.location.href.indexOf("callback") === -1 && url.hash) {
      const afterHash = url.hash.length > 2 ? url.hash.substring(2) : "";
      setTimeout(() => {
        setState({ lastLocation: afterHash, redirect: true });
      }, 250);
      setTimeout(() => {
        window.location.reload();
      }, 400);
    }
  }, []);

  const CallbackConfigured = () => <Callback userManager={props.userManager} />;
  const LoginConfigured = () => <Login userManager={props.userManager} />;
  const LogoutConfigured = () => <Logout userManager={props.userManager} />;
  const AppWithAuthenticationConfigured = () => <AppWithAuthentication />;

  return (
    <>
      <Switch>
        <Route exact={true} path="/silent_renew" component={SilentRenew} />
        <Route exact={true} path="/callback" component={CallbackConfigured} />
        <Route exact={true} path="/login" component={LoginConfigured} />
        <Route exact={true} path="/logout" component={LogoutConfigured} />
        <Route component={AppWithAuthenticationConfigured} />
      </Switch>
      {/* if legacy link has # then redirect to stripped hash route */}
      {state.redirect ? <Redirect to={state.lastLocation}></Redirect> : null}
    </>
  );
};

export default App;
