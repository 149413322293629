/* eslint-disable no-debugger */
import { ActionType, createAction } from "typesafe-actions";
import { HealthPlan } from "../../services/healthPlans/models/healthPlan";
import { OtherOrg } from "../../services/OtherOrgs/models/OtherOrg";
import { Practice } from "../../services/practices/models/practice";
import { Provider } from "../../services/providers/models/provider";
import RootAction from "../root/RootAction";

// Constants
export const LOAD_FAVORITES = "LOAD_FAVORITES";
export const LOAD_FAVORITES_SUCCESS = "LOAD_FAVORITES_SUCCESS";
export const LOAD_FAVORITES_FAIL = "LOAD_FAVORITES_FAIL";

export const LOAD_FAVORITE = "LOAD_FAVORITE";
export const LOAD_FAVORITE_SUCCESS = "LOAD_FAVORITE_SUCCESS";
export const LOAD_FAVORITE_FAIL = "LOAD_FAVORITE_FAIL";

export const REMOVE_FAVORITE = "REMOVE_FAVORITE";
export const REMOVE_FAVORITE_SUCCESS = "REMOVE_FAVORITE_SUCCESS";
export const REMOVE_FAVORITE_FAIL = "REMOVE_FAVORITE_FAIL";

export const LOAD_FAVORITEITEM_SUCCESS = "LOAD_FAVORITEITEM_SUCCESS";

// Action Creators
export const LoadFavoriteItemSuccess = createAction(LOAD_FAVORITEITEM_SUCCESS, (resolve) => {
  return (favoriteItem: HealthPlan | OtherOrg | Practice | Provider) => resolve(favoriteItem);
});
export const LoadFavorites = createAction(LOAD_FAVORITES);
export const LoadFavoritesSuccess = createAction(LOAD_FAVORITES_SUCCESS, (resolve) => {
  return (favorites: Array<string>) => resolve(favorites);
});
export const LoadFavoritesFail = createAction(LOAD_FAVORITES_FAIL, (resolve) => {
  return (error: any) => resolve(error);
});

export const LoadFavorite = createAction(LOAD_FAVORITE);
export const LoadFavoriteSuccess = createAction(LOAD_FAVORITE_SUCCESS, (resolve) => {
  return (favoriteItem: HealthPlan | OtherOrg | Practice | Provider) => resolve(favoriteItem);
});
export const LoadFavoriteFail = createAction(LOAD_FAVORITE_FAIL, (resolve) => {
  return (error: any) => resolve(error);
});

export const RemoveFavorite = createAction(REMOVE_FAVORITE);
export const RemoveFavoriteSuccess = createAction(REMOVE_FAVORITE_SUCCESS, (resolve) => {
  return (favorite: string) => resolve(favorite);
});
export const RemoveFavoriteFail = createAction(REMOVE_FAVORITE_FAIL, (resolve) => {
  return (error: any) => resolve(error);
});

// State
type FavoritesState = {
  loading: boolean;
  loaded: boolean;
  favorites: { [key: string]: HealthPlan | OtherOrg | Practice | Provider | null };
  error: any;
  hps: HealthPlan[];
  otherOrgs: OtherOrg[];
  practices: Practice[];
  providers: Provider[];
};

export const initialState: FavoritesState = {
  loading: false,
  loaded: false,
  favorites: {},
  error: null,
  hps: [],
  otherOrgs: [],
  practices: [],
  providers: [],
};

export type Action = ActionType<
  | typeof LoadFavorite
  | typeof LoadFavoriteSuccess
  | typeof LoadFavoriteFail
  | typeof LoadFavorites
  | typeof LoadFavoritesSuccess
  | typeof LoadFavoritesFail
  | typeof RemoveFavorite
  | typeof RemoveFavoriteSuccess
  | typeof RemoveFavoriteFail
  | typeof LoadFavoriteItemSuccess
>;

export const FavoritesReducer = (state: FavoritesState = initialState, action: RootAction): FavoritesState => {
  switch (action.type) {
    case LOAD_FAVORITEITEM_SUCCESS: {
      const { type } = action.payload.fields;
      const newObj = {
        ...state,
        loading: false,
        loaded: true,
      };

      if (type === "Health Plan Accreditation") {
        return {
          ...newObj,
          hps: [...newObj.hps, action.payload as HealthPlan],
        };
      }
      if (type === "Clinician") {
        return {
          ...newObj,
          providers: [...newObj.providers, action.payload as Provider],
        };
      }
      if (type === "Practice") {
        return {
          ...newObj,
          practices: [...newObj.practices, action.payload as Practice],
        };
      }
      if (type === "Other Organization") {
        return {
          ...newObj,
          otherOrgs: [...newObj.otherOrgs, action.payload as OtherOrg],
        };
      }
      return newObj;
    }
    case LOAD_FAVORITE: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case LOAD_FAVORITE_SUCCESS: {
      const { type } = action.payload.fields;

      const newObj = {
        ...state,
        loading: false,
        loaded: true,
        favorites: {
          ...state.favorites,
          [action.payload.id]: null,
        },
      };

      if (type === "Health Plan Accreditation") {
        return {
          ...newObj,
          hps: [...newObj.hps, action.payload as HealthPlan],
        };
      }
      if (type === "Clinician") {
        return {
          ...newObj,
          providers: [...newObj.providers, action.payload as Provider],
        };
      }
      if (type === "Practice") {
        return {
          ...newObj,
          practices: [...newObj.practices, action.payload as Practice],
        };
      }
      if (type === "Other Organization") {
        return {
          ...newObj,
          otherOrgs: [...newObj.otherOrgs, action.payload as OtherOrg],
        };
      }
      return newObj;
    }
    case LOAD_FAVORITE_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action,
      };
    }
    case LOAD_FAVORITES: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case LOAD_FAVORITES_SUCCESS: {
      action.payload.forEach((element) => {
        state.favorites[element] = null;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        favorites: {
          ...state.favorites,
        },
      };
    }
    case LOAD_FAVORITES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action,
      };
    }
    case REMOVE_FAVORITE: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case REMOVE_FAVORITE_SUCCESS: {
      const { [action.payload]: remove, ...rest } = state.favorites;
      const newhps = state.hps.length > 0 ? state.hps.filter((r) => r.id !== action.payload) : state.hps;
      const newProviders = state.providers.length > 0 ? state.providers.filter((r) => r.id !== action.payload) : state.providers;
      const newPractices = state.practices.length > 0 ? state.practices.filter((r) => r.id !== action.payload) : state.practices;
      const newOtherOrgs = state.otherOrgs.length > 0 ? state.otherOrgs.filter((r) => r.id !== action.payload) : state.otherOrgs;
      return {
        ...state,
        loading: false,
        loaded: true,
        favorites: {
          ...rest,
        },
        hps: [...newhps],
        otherOrgs: [...newOtherOrgs],
        practices: [...newPractices],
        providers: [...newProviders],
      };
    }
    case REMOVE_FAVORITE_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    default:
      return state;
  }
};
