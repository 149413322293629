import React from "react";
import ButtonBase, { ButtonBaseProps } from "@material-ui/core/ButtonBase";
import { useTheme, createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LogoMain from "../../../../../assets/images/logo.svg";
import LogoInverse from "../../../../../assets/images/logo-inverse.svg";
import LogoMobile from "../../../../../assets/images/logo-mobile.svg";

export interface LogoProps extends ButtonBaseProps {
  inverse?: boolean;
}

// styles
const useLogoStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      objectFit: "cover",
      marginTop: -8,
      [theme.breakpoints.up("lg")]: {
        marginTop: -10,
        height: 90,
      },
    },
  })
);

const Logo: React.FC<LogoProps> = ({ inverse, ...props }) => {
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const classes = useLogoStyles();

  return (
    <ButtonBase
      disableRipple
      onClick={() => {
        window.open("https://www.ncqa.org", "_blank");
      }}
      {...props}
    >
      <img className={classes.logo} src={lgUp ? (inverse ? LogoInverse : LogoMain) : LogoMobile} alt="NCQA" />
    </ButtonBase>
  );
};

export default React.memo(Logo);
