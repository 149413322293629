import { User } from "oidc-client";

export interface IUmRole {
  ApplicationName: string;
  ApplicationId: string;
  RoleName: string;
  RoleId: string;
  SystemGroupTypeName: string;
  SystemGroupTypeId: string;
  SystemGroupTypeEntityId: string;
}

export interface IUserProfile {
  sub: string;
  email?: string;
  username?: string;
  createdon?: string;
  createdby?: string;
  modifiedon?: string;
  modifiedby?: string;
  SfId?: string;
  given_name?: string;
  family_name?: string;
  UM_Role?: IUmRole | IUmRole[];
}

export type PolicyName = "user" | "admin" | "superadmin";

export const IsUserPolicy = (user: User | null): boolean => !!user;

export const IsAdminPolicy = (user: User | null): boolean => {
  if (user && user.profile) {
    const profile = user.profile as IUserProfile;
    if (profile.UM_Role && profile.UM_Role instanceof Array) {
      const satisfyingRoles = profile.UM_Role.filter(
        (x) =>
          x.ApplicationName === "ReportCards" &&
          x.SystemGroupTypeName.toLowerCase() === "system" &&
          x.SystemGroupTypeEntityId === "-1" &&
          (x.RoleName.toLowerCase().indexOf("admin") !== -1 || x.RoleName.toLowerCase().indexOf("superadmin") !== -1)
      );
      return satisfyingRoles.length > 0;
    }

    if (profile.UM_Role) {
      const satisfyRole =
        profile.UM_Role.ApplicationName === "ReportCards" &&
        profile.UM_Role.SystemGroupTypeName.toLowerCase() === "system" &&
        profile.UM_Role.SystemGroupTypeEntityId === "-1" &&
        (profile.UM_Role.RoleName.toLowerCase().indexOf("admin") !== -1 || profile.UM_Role.RoleName.toLowerCase().indexOf("superadmin") !== -1);
      return satisfyRole;
    }
  }

  return false;
};

export const IsSuperAdminPolicy = (user: User | null): boolean => {
  if (user && user.profile) {
    const profile = user.profile as IUserProfile;

    if (profile.UM_Role && profile.UM_Role instanceof Array) {
      const satisfyingRoles = profile.UM_Role.filter(
        (x) =>
          x.ApplicationName === "ReportCards" &&
          x.SystemGroupTypeName.toLowerCase() === "system" &&
          x.SystemGroupTypeEntityId === "-1" &&
          x.RoleName.toLowerCase().indexOf("superadmin") !== -1
      );
      return satisfyingRoles.length > 0;
    }

    if (profile.UM_Role) {
      const satisfyRole =
        profile.UM_Role.ApplicationName === "ReportCards" &&
        profile.UM_Role.SystemGroupTypeName.toLowerCase() === "system" &&
        profile.UM_Role.SystemGroupTypeEntityId === "-1" &&
        profile.UM_Role.RoleName.toLowerCase().indexOf("superadmin") !== -1;
      return satisfyRole;
    }
  }

  return false;
};
