import React from "react";
import Grid, { GridProps } from "@material-ui/core/Grid";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export interface WrapperProps extends GridProps {}

// styles
const useWrapperStyles = makeStyles(() =>
  createStyles({
    container: {
      height: "100vh",
      width: "100vw",
    },
  })
);

export const Wrapper: React.FC<WrapperProps> = ({ children, ...props }) => {
  const classes = useWrapperStyles();

  return (
    <Grid className={classes.container} {...props}>
      {children}
    </Grid>
  );
};
