const color = {
  primary: {
    light: "#E04E39",
    main: "#c8102e",
    dark: "#BD0F2B",
    contrastText: "#FFFFFF",
  },
  secondary: {
    light: "#E7FBFE",
    main: "#0099CC",
    dark: "#4F758B",
    contrastText: "#FFFFFF",
  },
  text: {
    primary: "#262626",
    secondary: "#333333",
    tertiary: "#888",
  },
  white: "#ffffff",
  subtle: "#9ea2a2",
  gray: "#adced9",
  skyBlue: "#00a9e0",
  dirtyWhite: "#F2F2F0",
  anotherBlack: "#111",
  subTitleGray: "#707372",
  verySubtle: "#54585a",
  onHover: "#09c",
};

export { color };
